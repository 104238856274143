<template>
    <v-row no-gutters>
        <v-col cols="12">
            <RealmBar :enterpriseId="$route.params.enterpriseId" :realm="realm" class="mb-6" v-if="realm"></RealmBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Dynamic DNS records</h1>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5">
                <v-col style="text-align: center" cols="12" sm="6">
                    <v-textarea :value="tinydnsconfig"></v-textarea>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mb-8">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card v-if="clientList">
                        <v-app-bar color="indigo" dark flat dense>
                            <v-app-bar-title>Clients ({{ clientList.length }})</v-app-bar-title>
                            <v-spacer/>
                            <v-btn icon @click="createNewItemDialog = true">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
                            </v-btn>
                        </v-app-bar>

                        <v-card-text v-if="clientList.length === 0">
                            <p>A client represents your application to the LoginFront Realm API to manage and authenticate users.</p>
                            <p>A client may have multiple API tokens so they can be rotated.</p>
                        </v-card-text>

                        <v-list dense v-if="clientList.length > 0">
                            <v-list-item v-for="(item, idx) in clientList" :key="idx" @click="openEditItemDialog(item)">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ item.label }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ item.principal_type }} {{ item.principal_id }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                    <v-dialog v-model="createNewItemDialog" max-width="600">
                    <v-card tile elevation="4" class="pa-0" max-width="600">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="indigo--text">Add Client</v-toolbar-title>
                        </v-toolbar>
                        <!-- <v-card-text class="px-5">
                        </v-card-text> -->
                        <v-form @submit.prevent="createItem" @keyup.enter.native.prevent="createItem" class="px-2">
                            <v-text-field v-model="newItemLabel" label="Label" hint="A label for this client; for example which application or server is using it" ref="newItemLabelInput"></v-text-field>
                            <v-text-field v-model="newItemOrigin" label="Origin" hint="Origin URL for this client, like https://app.example.com" ref="newItemOriginInput"></v-text-field>
                        </v-form>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="indigo white--text" @click="createItem" :disabled="!isNewItemFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="createNewItemDialog = false">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style>
/* vuetify places the icon too low from the top of the textarea */
.v-input .v-input__append-outer {
    margin-top: 4px !important;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';
import RealmBar from '@/components/cryptium_id/RealmBar.vue';

export default {
    components: {
        AccessDeniedOverlay,
        RealmBar,
    },
    data: () => ({
        realm: null,
        forbiddenError: false,
        enterprise: null,
        clientList: null,

        submitTimestamp: null,

        createNewItemDialog: false,
        newItemLabel: null,
        newItemOrigin: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryTextStyle: 'primaryTextStyle',
            primaryIconButtonStyle: 'primaryIconButtonStyle',
        }),
        isNewItemFormComplete() {
            return typeof this.newItemLabel === 'string' && this.newItemLabel.length > 0 && typeof this.newItemOrigin === 'string' && this.newItemOrigin.length > 0;
        },
    },
    watch: {
        createNewItemDialog(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.newItemLabel = '';
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('newItemLabelInput'); }, 1);
                });
            }
        },
        focus() {
            this.loadClientList();
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async loadEnterprise() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadEnterprise: true });
                const response = await this.$client.enterprise(this.$route.params.enterpriseId).self.get();
                console.log(`enterprise/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.enterprise = response;
                } else {
                    // TODO: redirect back to enterprise list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load enterprise', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadEnterprise: false });
            }
        },
        async loadRealm() {
            try {
                this.$store.commit('loading', { loadRealm: true });
                const result = await this.$client.cryptiumId(this.$route.params.enterpriseId).realm.get({ id: this.$route.params.realmId });
                console.log(`realm/dashboard.vue loadRealm result: ${JSON.stringify(result)}`);
                if (result) {
                    this.realm = result;
                }
            } catch (err) {
                console.error('realm/dashboard.vue loadRealm failed', err);
                const message = err.message ? err.message : null;
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load realm', message });
            } finally {
                this.$store.commit('loading', { loadRealm: false });
            }
        },
        async loadClientList() {
            try {
                this.$store.commit('loading', { loadClientList: true });
                const response = await this.$client.cryptiumIdRealm(this.$route.params.enterpriseId, this.$route.params.realmId).realmClient.search();
                if (response?.list) {
                    this.clientList = response.list;
                }
            } catch (err) {
                console.error('loadClientList failed', err);
            } finally {
                this.$store.commit('loading', { loadClientList: false });
            }
        },
        async createItem() {
            if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitTimestamp = Date.now();
            try {
                this.$store.commit('loading', { createItem: true });
                const item = {
                    label: this.newItemLabel,
                    origin: this.newItemOrigin,
                };
                const response = await this.$client.cryptiumIdRealm(this.$route.params.enterpriseId, this.$route.params.realmId).realmClient.create(item);
                if (response?.isCreated && response.id) {
                    this.createNewItemDialog = false;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    // this.clientList.push(response.item);
                    this.loadClientList();
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
                }
            } catch (err) {
                console.error('createItem failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
            } finally {
                this.$store.commit('loading', { createItem: false });
            }
        },
        async openEditItemDialog(item) {
            this.$router.push({ name: 'cryptium_id-realm-edit-client', params: { enterpriseId: this.$route.params.enterpriseId, realmId: this.$route.params.realmId }, query: { id: item.id } });
        },
        /*
        async editItem() {
            try {
                this.$store.commit('loading', { editItem: true });
                const response = await this.$client.main().dynamicSharedDomain.edit({ name: this.editItemLabel }, { status: this.editItemStatus });
                if (response?.isEdited) {
                    this.createNewItemDialog = false;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    const idx = this.clientList?.findIndex((item) => item.name === this.editItemLabel);
                    if (Number.isInteger(idx) && idx > -1) {
                        this.clientList.splice(idx, 1, { name: this.editItemLabel, status: this.editItemStatus });
                    } else {
                        this.loadClientList();
                    }
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
                }
            } catch (err) {
                console.error('editItem failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
            } finally {
                this.$store.commit('loading', { editItem: false });
            }
        },
        */
    },
    mounted() {
        this.loadEnterprise();
        this.loadRealm();
        this.loadClientList();
    },
};
</script>
